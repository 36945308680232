import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function NoPage() {
    return (
        <>
            <Navbar />
            <h2>Error 404 : Not Found</h2>
            <Footer />
        </>
    )
}